<template>
    <div class="flex flex-col gap-4 overflow-y-auto scroll-bar flex-1">
        <SubHeader :backButton="true" backRedirectionUrl="/field-admin?tab=blockadmin" :customCrumbLists="customBreadCrumbs" />
        <div class="flex gap-6 overflow-y-auto scroll-bar flex-1">
            <Menu class="self-start" :list="sections" @select="sectionClick($event)" :selected="getActiveSection.id" />
            <div class="flex-1 overflow-y-auto max-h-full min-h-full scroll-bar pr-2" v-if="getActiveSection">
                <component :headerList="tenantsListsHeader" :showLicenseAdd="false" :getTenantsList="getTenantsList" @handleFilterResult="handleFilterResult($event)" :loadingStates="loadingStates" :ref="getActiveSection.id" v-bind:is="getActiveSection.component" @selectAllTenants="selectAllTenants($event)" @handleFormSubmit="handleFormSubmit($event)" > </component>
            </div>
        </div>
    </div>
</template>

<script>
// import Loader from "@shared/components/loader"
import { assignedTenantIcon, FieldDetailsIcon } from "@shared/icons.js";
import { AssignTenantCard } from "@/pages/license/components/index";
import formSections from "../pages/form-draggable-section.vue";
import Menu from "@shared/components/menu";
import SubHeader from "@/components/SubHeader";
import axios from "@/axios";

export default {
    data() {
        return {
            sections: [
                {
                    id: "form-details",
                    name: "Form Details",
                    disabled: false,
                    component: formSections,
                    icon: FieldDetailsIcon,
                },
                {
                    id: "assigned-tenant",
                    name: "Assigned Tenant",
                    disabled: true,
                    component: AssignTenantCard,
                    icon: assignedTenantIcon,
                },
            ],
            activeSection: null,
            customBreadCrumbs: [],
            loadingStates: {
                fetchingDetails: false,
                submit: false
            },
            serchValues: {
                tenants: "",
            },
            licenseTenants: [],
            formId: null,
            tenantsListsHeader: [{ name: "Tenant Name" }],
        };
    },
    props: {},
    mounted() {
        this.formId = this.$route.params.id;
        this.checkActiveTab();
    },
    computed: {
        getActiveSection() {
            return this.activeSection || this.sections[0];
        },
        getTenantsList() {
            return this.licenseTenants?.filter((el) => el.title?.toLowerCase()?.includes(this.serchValues.tenants.toLowerCase()));
        },
    },
    components: {
        // Loader,
        Menu,
        SubHeader,
    },
    methods: {
        async sectionClick(section) {
            if (section.id === this.getActiveSection.id) return;
            this.activeSection = section;
            this.$router.push({ params: { tab: section.id } });
            this.customBreadCrumbs = [{ name: section.id }];
            await this.getAPIDetails(section.id);
            this.setBredCrumbs();
        },
        setBredCrumbs(crumb) {
            this.customBreadCrumbs = [];
            this.customBreadCrumbs = [{ name: this.activeSection.id }];
            if (crumb) {
                this.customBreadCrumbs.push({ name: crumb });
            }
        },
        async checkActiveTab() {
            let active_tab = this.$route.params.tab;
            let query_data = this.$route.query;
            active_tab ? this.$router.push({ params: { tab: active_tab }, query: query_data }) : this.$router.push({ params: { tab: "form-details" }, query: query_data });
            let tabData = this.sections.find((el) => el.id === active_tab);
            this.activeSection = tabData ? tabData : this.sections[0];
            await this.getAPIDetails(this.activeSection.id);
            this.setBredCrumbs();
        },
        handleFilterResult({ data, type }) {
            this.serchValues[type] = data;
        },

        async getAPIDetails(section_name) {
            let apiMapper = {
                "assigned-tenant": this.getTenantsData,
            };
            (await apiMapper[section_name]) && apiMapper[section_name]();
        },
        async handleFormSubmit({ data, section }) {
            let apiMapper = {
                "license-tenants": this.assignTenants,
            };
            await apiMapper[section] && apiMapper[section](data);
        },

        async getTenantsData() {
            this.loadingStates.fetchingDetails = true;
            try {
                let tenantLists = await this.getAllTenant();
                let assignedTenantLists = await this.getAssignedTenants();
                tenantLists = tenantLists?.map((tenant) => {
                    for (let i of assignedTenantLists) {
                        if (i.id === tenant.id) {
                            return { ...tenant, checked: true, license_count: i.license_count };
                        }
                    }
                    return { ...tenant, checked: false, license_count: null };
                });
                this.licenseTenants = tenantLists;
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.fetchingDetails = false;
        },

        async getAllTenant() {
            try {
                let { data } = await axios.get("/tenant");
                return data.map((el) => ({ id: el.id, title: el.title })) || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },

        async getAssignedTenants() {
            try {
                let { data } = await axios.get(`/delvium-admin/forms/tenant/${this.formId}`);
                return data.tenants || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },
        selectAllTenants(event) {
            this.licenseTenants = this.licenseTenants.map((el) => ({ ...el, checked: event }));
        },
        async assignTenants() {
            this.loadingStates.submit = true;
            try {
                let payload = {
                    tenants: this.getTenantsList.filter((el) => el.checked).map((tenant) => tenant.id),
                    checks: [this.formId]
                };
                let { data } = await axios.post(`/delvium-admin/assign/forms`, payload);
                this.$toast.success(data.message || "block assigned");
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.submit = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
